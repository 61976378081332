import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import * as SchoolActions from "../../../reducers/schoolReducer";
import { ScheduleAPI, UserAPI } from "src/API";
import UserImage from "src/components/structure/UserImage";
import * as moment from "moment";
import { error, success } from "src/components/structure/Alert";

interface IAttendanceModeStudentConfirmProps {
  appActions: any; 
  schoolState: any;
  schoolActions: any;
  history: any;
  match: any;
}

interface IAttendanceModeStudentConfirmState {
  loading: boolean;
  user: any;
  event: any;
  userDidAttend: boolean;
  minutes: number;
  lastAttendance: string;
  previousAttendance: any[];
}

const largeSize = 24;

class Starter extends React.Component<IAttendanceModeStudentConfirmProps, IAttendanceModeStudentConfirmState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: true,
      user: {},
      event: {},
      userDidAttend: false,
      minutes: 0,
      lastAttendance: "",
      previousAttendance: [],
    };

    this.setup = this.setup.bind(this);
    this.confirmAttendance = this.confirmAttendance.bind(this);
    this.removeAttendance = this.removeAttendance.bind(this);
  }

  public componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <Card title="Confirm" loading={this.state.loading} help="">
      <div className="row">
        <div className="col-lg-12" style={{textAlign: "center"}}>
          <strong style={{fontSize: largeSize}}>Confirm</strong>
        </div>
      </div>
        <div className="row">
          <div className="col-lg-12" style={{textAlign: "center"}}>
            <UserImage schoolId={this.props.schoolState.school.id} userId={this.props.match.params.userId} height={80} width={80} size="thumb" useSensor={false} allowUploads={false} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group" style={{textAlign: "center"}}>
              <strong style={{fontSize: largeSize + 8}}>{this.state.user.firstName} {this.state.user.lastName} {this.state.user.nickname && this.state.user.nickname !== "" ? this.state.user.nickname : ""}</strong>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12" style={{textAlign: "center"}}>
            <strong style={{fontSize: largeSize - 6}}>For {this.state.event.name}</strong>
          </div>
        </div>
        <div className="row" style={{marginBottom: 20}}>
          <div className="col-lg-12" style={{textAlign: "center"}}>
            <strong style={{fontSize: largeSize - 8}}>{moment(this.state.event.startTime).format("h:mm")} to {moment(this.state.event.endTime).format("h:mm")} </strong>
          </div>
        </div>

        {this.state.userDidAttend ? (
          <div className="row">
            <div className="col-lg-12">
              <button className="btn btn-block btn-danger" onClick={this.removeAttendance}>Remove Attendance</button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <button className="btn btn-block btn-success" onClick={this.confirmAttendance}>Mark Attendance</button>
            </div>
          </div>
        )}
        <div className="row" style={{marginTop: 20}}>
          <div className="col-lg-12">
            <button className="btn btn-block btn-primary" onClick={this.goBack}>Nevermind</button>
          </div>
        </div>
        <div className="row" style={{marginTop: 20}}>
          <div className="col-lg-12">
            <strong>Last Attended: {this.state.lastAttendance}</strong>
          </div>
        </div>
        <div className="row" style={{marginTop: 20, textAlign: "center"}}>
          <div className="col-lg-12">
            <strong>Attendance Previous Two Weeks: {this.state.previousAttendance.length}</strong><br />
            {this.state.minutes > 0 && (<span>for a total of {this.minutesDisplay(this.state.minutes)}!</span>)}
          </div>
        </div>
        <hr />
        {this.state.previousAttendance.map((att: any) => {
          return (
          <div key={att.id}>
            <div className="row">
              <div className="col-lg-4 col-sm-4 col-xs-6">
                {att.startTime.format("ddd MMM do hh:mm A")}
              </div>
              <div className="col-lg-4 col-sm-4 col-xs-6">
                {att.name}
              </div>
              <div className="col-lg-4 col-sm-4 hidden-xs">
                {att.eventTypeName}
              </div>
            </div>
          </div>
          );
        })}
      </Card>
    );
  }

  private setup(){
    this.setState({ loading: true }, async () => {
      try{
        const twoWeeksAgo = moment().local().subtract(2, "week").format("YYYY-MM-DDTHH:mm:ss") + "Z";
        const today = moment().local().add(1, "hour").format("YYYY-MM-DDTHH:mm:ss") + "Z";
        const schoolId = this.props.schoolState.school.id;
        const userId = this.props.match.params.userId;
        const eventId = this.props.match.params.eventId;
        const uResult = await UserAPI.getUserProfile(schoolId, userId);
        const eResult = await ScheduleAPI.getEvent(schoolId, eventId);
        const aResult = await ScheduleAPI.getEventAttendance(schoolId, eventId);
        const pResult = await ScheduleAPI.getUserAttendance(schoolId, userId, {start: twoWeeksAgo, end: today});

        const user = uResult.body.data;
        let userDidAttend = false;
        for(const a of aResult.body.data){
          if(a.id === user.id){
            userDidAttend = true;
          }
        }
        const attendance: any[] = [];
        let minutes = 0;
        for(const a of pResult.body.data){
          // there's a current bug where the attendance times aren't coming back in
          // utc format, so we need to hack it here
          a.startTime  = moment(moment(a.startTime).format("YYYY-MM-DDTHH:mm:ss") + "Z");
          a.endTime = moment(moment(a.endTime).format("YYYY-MM-DDTHH:mm:ss") + "Z");
          minutes += a.endTime.diff(a.startTime, "minutes");
          attendance.push(a);
        }
        const lastAttendance = attendance.length === 0 ? "More Than Two Weeks Ago" : moment(attendance[0].startTime).local().format("MM/DD/YY hh:mm A");
        this.setState({loading: false, user, userDidAttend, event: eResult.body.data, previousAttendance: attendance, lastAttendance, minutes});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private removeAttendance(){
    this.setState({ loading: true }, async () => {
      try{
        await ScheduleAPI.removeUserAttendance(this.props.schoolState.school.id, this.props.match.params.eventId, this.props.match.params.userId);
        success("User attendance updated!");
        history.back();
      }catch(err){
        error("Could not log that attendance. Please talk to an instructor.");
        this.setState({ loading: false });
      }
    });
  }

  private confirmAttendance(){
    this.setState({ loading: true }, async () => {
      try{
        await ScheduleAPI.saveUserAttendance(this.props.schoolState.school.id, this.props.match.params.eventId, this.props.match.params.userId);
        success("User attendance updated!");
        history.back();
      }catch(err){
        error("Could not log that attendance. Please talk to an instructor.");
        this.setState({ loading: false });
      }
    });
  }

  private goBack(){
    history.back();
  }

  private minutesDisplay(numberMinutes: number): string {
    if(numberMinutes < 60){
      return `${numberMinutes}`;
    }
    const hours = Math.round(numberMinutes / 60);
    const remainingMinutes = Math.round(numberMinutes % 60);
    return `${hours} hours and ${remainingMinutes} minutes`;
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    schoolActions: bindActionCreators(SchoolActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Starter) as React.ComponentType<any>);