import { makeCall } from "./client";

export class BeltsAPI {

  /**
   * Get the school's belts
   * @param schoolId 
   * @param params 
   */
  public getSchoolBelts(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/belts`, params);
  }

}