import { makeCall,makeUrl } from "./client";

export class SchoolAPI {

  /**
   * Get the school's info
   * @param schoolId 
   * @param params 
   */
  public getSchoolInfo(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}`, params);
  }

  /**
   * Get the URL for the school's logo
   * @param schoolId 
   */
  public getSchoolLogoString(schoolId: number): string {
    return makeUrl(`schools/${schoolId}/logo`);
  }


}