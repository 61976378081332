import { makeCall } from "./client";

export class ScheduleAPI {
  
  /**
   * Get all of the events for a school between a specific period of time
   * @param schoolId 
   * @param start 
   * @param end 
   * @param params 
   */
  public getEventsBetweenDays(schoolId: number, start: string, end: string, params: any = {}): Promise<any> {
    params.start = start;
    params.end = end;
    return makeCall("get", `schools/${schoolId}/schedule`, params);
  }

  /**
   * Get the event types created by a school
   * @param schoolId 
   * @param params 
   */
  public getEventsTypes(schoolId: number,params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/schedule/eventTypes`, params);
  }

  /**
   * Get all of the event categories for the system
   * @param params 
   */
  public getEventsCategories(params: any = {}): Promise<any> {
    return makeCall("get", `events/categories`, params);
  }

  public getEvent(schoolId: number, eventId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/schedule/${eventId}`, params);
  }

  public getEventAttendance(schoolId: number, eventId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/schedule/${eventId}/attendance`, params);
  }

  public getUserAttendance(schoolId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users/${userId}/attendance`, params);
  }

  public saveUserAttendance(schoolId: number, eventId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("put", `schools/${schoolId}/schedule/${eventId}/attendance/users/${userId}`, params);
  }

  public removeUserAttendance(schoolId: number, eventId: number, userId: number, params: any = {}): Promise<any> {
    return makeCall("delete", `schools/${schoolId}/schedule/${eventId}/attendance/users/${userId}`, params);
  }
}