
import { UserAPI, BeltsAPI, SchoolUsersAPI } from "../API";

export async function refreshData(user: any, userActions: any, schoolActions: any, alertsActions: any, setupActions: any, redirect: boolean = false, history: any = null) : Promise<any> {
  return new Promise(async (resolve: any, reject: any) => {
    try {
      // get the school information
      const schoolsResult = await UserAPI.getUserSchools(user.id);
      const schools = schoolsResult.body.data;
      if(schools.length === 0){
        return reject("You must be an assistant or administrator for a school");
      }
      let foundSchool = false;
      let selectedSchool: any = {};
      for(const s of schools){
        if(s.role === "administrator" || s.role === "assistant"){
          foundSchool = true;
          selectedSchool = s;
          break;
        }
      }
      if(!foundSchool){
        return reject("You must be an assistant or administrator for a school");
      }
      window.localStorage.schoolId = selectedSchool.id;
  
      const userPayload: any = {
        loggedIn: true,
        schools,
        selectedSchool,
        user
      };
  
      const beltsResult = await BeltsAPI.getSchoolBelts(selectedSchool.id);
      const schoolBelts = beltsResult.body.data;
      selectedSchool.belts = schoolBelts;

      // get the users for the school
      const schoolUsersResult = await SchoolUsersAPI.getUsersInSchool(selectedSchool.id, {sort: "lastName"});
      const users: any[] = [];
      for(const u of schoolUsersResult.body.data){
        if(u.role === "user" || u.role === "inactive"){
          continue;
        }
        u.belt = null;
        for(const b of schoolBelts){
          if(b.id === u.beltId){
            u.belt = b;
          }
        }
        // TODO: check contract status
        
        users.push(u);
      }
  
      await userActions.loginUser(userPayload);
      await schoolActions.setSchool(selectedSchool);
      await schoolActions.setUsers(users);

      if(redirect){
        history.push("/dashboard");
      }
      return resolve({
        userPayload,
        selectedSchool,
        users,
      });
      
    } catch(e){
      return reject("Could not log you in");
    }
  });
  
}