import { makeCall } from "./client";

export class SchoolUsersAPI {

  /**
   * Get the users in a school
   * @param schoolId 
   * @param params 
   */
  public getUsersInSchool(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users`, params);
  }

}