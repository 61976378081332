import * as React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import Card from "../../structure/Card";
import { error } from "../../structure/Alert";
import { refreshData } from "../../../utils/login";

import { UserAPI } from "../../../API";

import * as AlertsActions from "../../../reducers/alertsReducer";
import * as SchoolActions from "../../../reducers/schoolReducer";
import * as UserActions from "../../../reducers/userReducer";
import * as SetupActions from "../../../reducers/setupReducer";
import { bindActionCreators } from "redux";

interface ILoginScreenProps {
  alertsActions: any;
  schoolActions: any;
  userActions: any;
  setupActions: any;
  userState: any;
  history: any;
}

const helpText = `This application is designed for school administrators and assistants. If you are an administrator or assistant and cannot log on, you should try to reset your password. Please ensure you verified your account by checking your email. If you still cannot access your account, please contact support@treelightsoftware.com.`;
class Login extends React.Component<ILoginScreenProps, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false,
      expired: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateField = this.updateField.bind(this);
    this.checkEnter = this.checkEnter.bind(this);
  }

  public componentDidMount(){
    const expired = window.localStorage.getItem("expired");
    if(expired && expired === "yes"){
      this.props.userActions.logoutUser();
      window.localStorage.setItem("expired", "no");
      this.setState({expired: true});
    }
  }

  public async handleSubmit() {
    this.setState({loading: true}, async () => {
      
      try {
        const result = await UserAPI.loginUser(this.state.username, this.state.password);
        const user = result.body.data;
        user.jwt = "";
        user.secretKey = "";
        user.access_token = "";
        user.refresh_token = "";

        window.localStorage.user = JSON.stringify(user);
        await refreshData(user, this.props.userActions, this.props.schoolActions, this.props.alertsActions, this.props.setupActions, true, this.props.history);

      } catch (e) {
        error("Could not log you in. Please ensure that you are an administrator or assistant for your school.");
        this.setState({loading: false});
      }
    });
  }

  public render() {
    if(this.props.userState.loggedIn){
      return (<Redirect
        to="/dashboard" />);
    }

    return (
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          {process.env.REACT_APP_ENV === "demo" && (
            <Card title="Welcome!">
              <div className="row">
                <div className="col-md-12">
                  <p><strong>Welcome to the KDojang Demo application!</strong></p>
                  <p>The purpose of this demo app is to provide a quick and easy way to see what KDojang has to offer your school with some data pre-loaded. There are a few things you should know before using the demonstration application:</p>
                  <ul>
                    <li>To login, the username is <em>demo</em> and the password is <em>demo_account</em></li>
                    <li>The site is reset on a regular basis, so anything you see is temporary. The content is automatically generated and may not reflect a typical school</li>
                    <li>Many features are disabled when using the dmonstration to prevent fraud or abuse. Notably, you cannot: 
                      <ul>
                        <li>Send emails</li>
                        <li>Send SMS</li>
                        <li>Charge real credit cards</li>
                        <li>Change images or logos</li>
                        <li>Upload files</li>
                        <li>Setup a new school (the full app is free to use, so feel free to create a school there to dive in further!</li>
                      </ul>
                    </li>
                  </ul>
                  <p>If you have any questions at all, please feel free to email support@treelightsoftware.com for more information!</p>
                </div>
              </div>
            </Card>
          )}
          {!this.props.userState.loggedIn ? (
            <Card title="Login" help={helpText}>
              {this.state.expired  && (
                <div className="row">
                  <div className="col-md-12 bg-danger text-white" style={{ padding: 15, marginBottom: 20 }}>
                    <strong>You have been logged out as your authentication has expired. Please log back in.</strong>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Username</label>
                    <input type="text" id="username" className="form-control" value={this.state.username} onChange={this.updateField} onKeyUp={this.checkEnter} />
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <input type="password" id="password" className="form-control" value={this.state.password} onChange={this.updateField} onKeyUp={this.checkEnter} />
                  </div>
                  <div className="form-group">
                    {this.state.loading ? (<div className="glyphicon glyphicon-repeat normal-right-spinner" style={{textAlign: "center", width: "100%"}} />) :
                      (<button className="btn btn-block btn-primary" onClick={this.handleSubmit}>Login</button>)}
                    
                  </div>
                </div>
                <div className="col-md-6">
                  <p>This application is intended for school <strong>administrators</strong> and <strong>assistants</strong> so that they may manage the attendance in a dedicated, limited mode. By using this application you agree to our <a href="/terms" target="_new">Terms of Service and Privacy Policy</a>.</p>
                
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pull-right">
                  <a href="https://app.kdojang.com/reset" target="_new">Forgot?</a><br />
                </div>
              </div>
            </Card>) : (
              <Card title="Login">
                <div className="row">
                  <div className="col-md-6">
                    You are logged in!
              </div>
                </div>
              </Card>
            )}
        </div>
      </div>
    );
  }

  private updateField(e: any) {
    const ns: any = this.state;
    ns[e.target.id] = e.target.value;
    this.setState(ns);
  }

  private checkEnter(e: any){
    if(e.keyCode === 13) {
      this.handleSubmit();
    }
  }
}


const mapStateToProps = function map(s: any) {
  return {
    userState: s.userState
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    userActions: bindActionCreators(UserActions, dispatch),
    schoolActions: bindActionCreators(SchoolActions, dispatch),
    alertsActions: bindActionCreators(AlertsActions, dispatch),
    setupActions: bindActionCreators(SetupActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login) as React.ComponentType<any>);