import * as React from "react";
import UserImage from "src/components/structure/UserImage";

interface IListItemProps {
  onSelect: any;
  user: any;
  schoolId: number;
}

interface IListItemState {
  loading: boolean;
}

export default class ListItem extends React.Component<IListItemProps, IListItemState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false
    };

    this.handleSelect = this.handleSelect.bind(this);

  }

  public render() {
    return (
      <div className={["row row-clickable ", this.props.user.rowClassName].join(" ")} key={this.props.user.id} style={{marginBottom: 7, marginTop: 7, height: 40}} onClick={this.handleSelect}>
        <div className="col-lg-1  col-sm-2  col-xs-2">{this.returnBeltDiv(this.props.user.belt)}</div>
        <div className="col-lg-1  col-sm-2  col-xs-2" style={{ textAlign: "center" }}>
          <UserImage userId={this.props.user.id} schoolId={this.props.schoolId} allowUploads={false} height={40}  width={40} size="thumb" />
        </div>
        <div className="col-lg-10 col-sm-8 col-xs-8">
          <strong>{this.props.user.firstName} {this.props.user.lastName}</strong>
          {this.props.user.isBirthDay && (<span style={{fontWeight: "bold"}}> - Birthday Today!</span>)}
          {this.props.user.isBirthWeek && (<span> - Birthday This Week!</span>)}
          {this.props.user.isBirthMonth && (<span> - Birthday This Month!</span>)}
        </div>
      </div>
    );
  }

  private handleSelect(){
    this.props.onSelect(this.props.user);
  }


  private returnBeltDiv(belt: any){
    let topColor = "#FFFFFF";
    let bottomColor = "#FFFFFF";
    let numberDisplay = null;
    if(belt){
      if(belt.topColor) {
        topColor = "#" + belt.topColor;
      }
      if(belt.bottomColor) {
        bottomColor = "#" + belt.bottomColor;
      }
      if(belt.numberDisplay){
        numberDisplay = (<div style={{color: "#" + belt.numberDisplayColor, width: "100%", textAlign: "center"}}>{belt.numberDisplay}</div>);
      }
    }


    return (
      <div style={{border: "thin solid black", height: 40}}>
        <div style={{height: "50%", backgroundColor: topColor}} />
        <div style={{height: "50%", backgroundColor: bottomColor}}>
          {numberDisplay}
        </div>
      </div>
    );
  }

}