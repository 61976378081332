import * as belts from "./belts";
import * as news from "./news";
import * as schedule from "./schedule";
import * as schools from "./schools";
import * as schoolUsers from "./schoolUsers";
import * as user from "./user";

export const BeltsAPI = new belts.BeltsAPI();
export const NewsAPI = new news.NewsAPI();
export const ScheduleAPI = new schedule.ScheduleAPI();
export const SchoolsAPI = new schools.SchoolAPI();
export const SchoolUsersAPI = new schoolUsers.SchoolUsersAPI();
export const UserAPI = new user.UserAPI();