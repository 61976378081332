import { makeCall } from "./client";

export class NewsAPI {
  
  /**
   * Gets the news
   */
  public getNews(params: any = {}): Promise<any> {
    return makeCall("get", "news", params);
  }

  public getSchoolNews(schoolId: number, params: any = {}): Promise<any> {
    return makeCall("get", `schools/${schoolId}/news`, params);
  }

}