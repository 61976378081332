import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {Link, withRouter } from "react-router-dom";

import * as AppActions from "../../reducers/appReducer";
import * as UserActions from "../../reducers/userReducer";
import * as AlertsActions from "../../reducers/alertsReducer";
import * as SchoolActions from "../../reducers/schoolReducer";
import * as SetupActions from "../../reducers/setupReducer";

import logo from "../../img/brand.png";

interface INavProps {
  alertsActions: any;
  appActions: any;
  schoolActions: any;
  setupActions: any;
  userActions: any;

  appState: any;
  alertsState:any;
  scheduleState: any;
  schoolState: any;
  userState: any;
  setupState: any;
  history: any;
  location: any;
}

interface INavState {
  open: boolean;
  users: any;
}

class NavBar extends React.Component<INavProps, INavState> {

  constructor(props: any){
    super(props);
    this.state = {
      open: true,
      users: []
    };

    this.logout = this.logout.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleHelp = this.toggleHelp.bind(this);
  }

  public logout(){
    this.props.userActions.logoutUser();
    window.localStorage.clear();
    this.props.history.push("/login");
  }

  public toggleMenu(e: any){
    e.preventDefault();
    this.props.appActions.toggleMenu();
  }

  public toggleHelp(e: any){
    e.preventDefault();
    this.props.appActions.toggleHelp();
  }


  get links(){
    if(this.props.userState.loggedIn) {
      return (
        <div>
          <ul className="nav navbar-nav">
            <li><a href="#" onClick={this.toggleMenu} id="menu_toggle">Menu</a></li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
            <li><a href="#" onClick={this.logout}>Logout</a></li>
          </ul>
        </div>
      );
    } else {
      return (
        <div>
          <ul className="nav navbar-nav">
            <li><Link to="/login" id="nav-login">Login</Link></li>
          </ul>
        </div>
      );
    }
  }

  get header(){
    if(this.props.userState.loggedIn) {
      return (
        <a className="navbar-brand" href="#" onClick={this.toggleMenu}><img src={logo} alt="logo" height="24px" width="24px" /></a>
      );
    } else {
      return (
        <a className="navbar-brand" href="/"><img src={logo} alt="logo" height="24px" width="24px" /></a>
      );
    }
  }
  
  public render (){
    return (
      <div id="navbar">
        <nav className="navbar navbar-inverse" style={{borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px"}}>
          <div className="navbar-header">
              {this.header}
          </div>
          <div className="collapse navbar-collapse">
            {this.links}
          </div>
        </nav>
      </div>);
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    userState: s.userState,
    alertsState: s.alertsState,
    schoolState: s.schoolState,
    scheduleState: s.scheduleState,
    setupState: s.setupState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    alertsActions: bindActionCreators(AlertsActions, dispatch),
    schoolActions: bindActionCreators(SchoolActions, dispatch),
    setupActions: bindActionCreators(SetupActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar)  as React.ComponentType<any>);