import { makeCall, makeUrl } from "./client";

export class UserAPI {
  /**
   * Attempts to login a user
   * @param username 
   * @param password 
   */
  public loginUser(username: string, password: string): Promise<any> {
    return makeCall("post", "users/login", {username, password});
  }

  /**
   * Get the schools a user is a member of
   * @param userId 
   */
  public getUserSchools(userId: number): Promise<any> {
    return makeCall("get", `users/${userId}/schools`);
  }

  /**
   * Get a user profile
   * @param schoolId 
   * @param userId 
   */
  public getUserProfile(schoolId: number, userId: number): Promise<any> {
    return makeCall("get", `schools/${schoolId}/users/${userId}`);
  }

  /**
   * Uploade a new profile image using a multipart form
   * @param schoolId 
   * @param userId 
   * @param file 
   */
  public uploadUserProfileImage(schoolId: number, userId: number, file: any = {}): Promise<any> {
    return makeCall("put", `schools/${schoolId}/users/${userId}/images/profile`, file, {asMultipartFile: true});
  }

  /**
   * Get the URL for the user's profile image
   * @param schoolId 
   * @param userId 
   * @param asThumb 
   */
  public getUserProfileImageString(schoolId: number, userId: number, asThumb: boolean = false, addJWT: boolean = false): string {
    const thumbQuery = asThumb ? "thumb=1&" : "";
    let jwtQuery = "";
    if(addJWT){
      // grab the JWT from local storage and add it
      jwtQuery = `jwt=${window.localStorage.jwt}&`;
    }
    return makeUrl(`schools/${schoolId}/users/${userId}/images/profile?${thumbQuery}${jwtQuery}`);
  }


}