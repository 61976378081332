import * as React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import { NewsAPI } from "src/API";
import * as moment from "moment";

interface IStarterProps {
  appActions: any;
  history: any;
  schoolState: any;
}

interface IStarterState {
  loading: boolean;
  news: any[];
}

class Starter extends React.Component<IStarterProps, IStarterState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      news: [],
    };

    this.setup = this.setup.bind(this);
  }

  public componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <>
        <div className="row" style={{marginBottom: 15}}>
          <div className="col-lg-12">
              <Link to="/attendance" className="btn btn-block btn-primary">To Attendance</Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <Card title="News" loading={this.state.loading} help="">
              {this.state.news.length === 0 && (
                <div className="row">
                  <div className="col-lg-12">
                    <strong>No news to display</strong>
                  </div>
                </div>
              )}
              {this.state.news.map((news) => {
                return (
                  <div key={news.id} style={{marginBottom: 15}}>
                    <div className="row">
                      <div className="col-lg-4 col-sm-6">
                        <strong>{moment(news.posted).format("MM/DD/YY")}</strong>
                      </div>
                      <div className="col-lg-8 col-sm-6">
                        <strong>{news.title}</strong>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        {news.body}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Card>
          </div>
        </div>
      </>
    );
  }

  private setup(){
    this.setState({ loading: true }, async () => {
      const newsMap: any = {};
      const news: any[] = [];
      const start = moment().subtract(3, "month").format("YYYY-MM-DDT") + "00:00:00Z";
      const end = moment().format("YYYY-MM-DDT") + "23:59:59Z";
      try{
        const platformResult = await NewsAPI.getNews({start, end});
        platformResult.body.data.map((item: any) => {
          newsMap[item.id] = news;
        });
      }catch(err){//
      }

      try{
        const schoolResult = await NewsAPI.getSchoolNews(this.props.schoolState.school.id, {start, end});
        schoolResult.body.data.map((item: any) => {
          newsMap[item.id] = item;
        });
      }catch(err){//
      }

      // de dupe on the ids
      Object.keys(newsMap).map((key) => {
        news.push(newsMap[key]);
      });

      news.sort((a, b) => {
        return moment(a.posted).isAfter(moment(b.posted)) ? -1 : 1;
      });
      this.setState({ loading: false, news});
    });
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Starter) as React.ComponentType<any>);