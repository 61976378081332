import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import * as SchoolActions from "../../../reducers/schoolReducer";
import { BeltsAPI, ScheduleAPI, SchoolUsersAPI } from "src/API";
import * as moment from "moment";
import AttendanceModeStudentListItem from "./AttendanceModeStudentListItem";
import { error } from "src/components/structure/Alert";

interface IAttendanceModeProps {
  appActions: any; 
  schoolState: any;
  schoolActions: any;
  history: any;
  match: any;
}

interface IAttendanceState {
  loading: boolean;
  users: any[];
  filteredUsers: any[];
  search: string;
}

class Starter extends React.Component<IAttendanceModeProps, IAttendanceState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: true,
      users: [],
      filteredUsers: [],
      search: "",
    };

    this.setup = this.setup.bind(this);
    this.refresh = this.refresh.bind(this);
    this.selectUser = this.selectUser.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
  }

  public componentDidMount(){
    this.setup();
  }

  public render() {
    return (
        <Card title="Select a Student" loading={this.state.loading} help="">
          <div className="row">
            <div className="col-lg-6 col-sm-12">
              <button className="btn btn-block btn-success" onClick={this.goBack} style={{marginBottom: 10}}>Go Back</button>
            </div>
            <div className="col-lg-6 col-sm-12">
              <button className="btn btn-block btn-primary" onClick={this.refresh} style={{marginBottom: 10}}>Refresh</button>
            </div>
          </div>
          <div className="form-group" style={{marginTop:15, marginBottom: 15}}>
            <input placeholder="Search..." type="text" id="search" className="form-control" value={this.state.search} onChange={this.updateSearch} />
          </div>
          {this.state.filteredUsers.map((u: any) => {
            return (<AttendanceModeStudentListItem key={u.id} user={u} schoolId={this.props.schoolState.school.id} onSelect={this.selectUser} />);
          })}
        </Card>
    );
  }

  private setup(){
    this.setState({ loading: true }, async () => {
      try{
        // first get the attendance for this event
        const schoolId = this.props.schoolState.school.id;
        const eventId = this.props.match.params.eventId;
        const attendanceResult = await ScheduleAPI.getEventAttendance(schoolId, eventId);
        const attendance = attendanceResult.body.data;

        const users: any[] = [];
        const startOfWeek = moment().startOf("week");
        const endOfWeek = moment().endOf("week");
        const startOfMonth = moment().startOf("month");
        const endOfMonth = moment().endOf("month");
        const todayString = moment().format("MM/DD");

        for(const u of this.props.schoolState.users){
          if(u.role === "user" || u.role === "inactive"){
            continue;
          }
          u.belt = null;
          u.didAttend = false;
          u.age = "";
          u.birthDate = "";
          u.isBirthWeek = false;
          u.isBirthMonth = false;
          u.isBirthDay = false;
          u.rowClassName = "";
          for(const b of this.props.schoolState.school.belts){
            if(b.id === u.beltId){
              u.belt = b;
            }
          }
          // TODO: check contract status
          const dob = moment(u.dob);
          if(dob.isValid()){
            u.age = moment().diff(dob, "years") + " years old";
            u.birthDate = dob.format("MM/DD");
            const temp = moment(u.dob);
            temp.year(moment().year());
            if(temp.format("MM/DD") === todayString){
              u.isBirthDay = true;
              u.rowClassName = "is-birth-day";
            }
            if(temp.isAfter(startOfWeek) && temp.isBefore(endOfWeek)){
              u.isBirthWeek = true;
              u.rowClassName = "is-birth-week";
            } else if(temp.isAfter(startOfMonth) && temp.isBefore(endOfMonth)){
              u.isBirthMonth = true;
              u.rowClassName = "is-birth-month";
            }
          }
          for(const a of attendance){
            if(a.id === u.id){
              u.didAttend = true;
              u.rowClassName += " event-attended";
              break;
            }
          }
          users.push(u);
        }
        users.sort((a: any, b: any): number => {
          return a.firstName > b.firstName ? 1 : -1;
        });
        this.setState({loading: false, users, filteredUsers: users});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private selectUser(user: any){
    this.props.history.push(`/attendance/events/${this.props.match.params.eventId}/users/${user.id}`);
  }

  private refresh(){
    this.setState({ loading: true }, async () => {
      try{
        const schoolId = this.props.schoolState.school.id;
        const school = this.props.schoolState.school;
        const schoolUsersResult = await SchoolUsersAPI.getUsersInSchool(schoolId, {sort: "lastName"});
        const beltsResult = await BeltsAPI.getSchoolBelts(schoolId);
        const schoolBelts = beltsResult.body.data;
        school.belts = schoolBelts;

        const users: any[] = [];
        for(const u of schoolUsersResult.body.data){
          if(u.role === "user" || u.role === "inactive"){
            continue;
          }
          u.belt = null;
          for(const b of school.belts){
            if(b.id === u.beltId){
              u.belt = b;
            }
          }
          // TODO: check contract status
          
          users.push(u);
        }
        await this.props.schoolActions.setSchool(school);
        await this.props.schoolActions.setUsers(users);
        this.setup();
      }catch(err){
        error("Could not refresh users, please speak with an instructor.");
        this.setState({ loading: false });
      }
    });
  }

  private updateSearch(e: any){
    const search = e.target.value;
    const users = this.state.users;
    const filteredUsers = users.filter((u: any) => {
      if(search === ""){
        return true;
      }
      return u.firstName.indexOf(search) > -1 || u.lastName.indexOf(search) > -1 || u.nickname.indexOf(search) > -1;
    });
    this.setState({filteredUsers, search});
  }

  private goBack(){
    history.back();
  }
}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    schoolActions: bindActionCreators(SchoolActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Starter) as React.ComponentType<any>);