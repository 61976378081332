import * as React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Card from "../../structure/Card";
import * as AppActions from "../../../reducers/appReducer";
import * as SchoolActions from "../../../reducers/schoolReducer";
import { ScheduleAPI   } from "src/API";
import * as moment from "moment";
import DatePicker from "src/components/structure/DatePicker";

interface IAttendanceModeProps {
  appActions: any; 
  schoolState: any;
  schoolActions: any;
  history: any;
}

interface IAttendanceState {
  loading: boolean;
  selectedDay: moment.Moment;
  events: any[];
}

class AttendanceMode extends React.Component<IAttendanceModeProps, IAttendanceState> {

  constructor(props: any){
    super(props);
    this.state = {
      loading: false,
      selectedDay: moment().local(),
      events: [],
    };

    this.setup = this.setup.bind(this);
    this.updateSelectedDate = this.updateSelectedDate.bind(this);
  }

  public componentDidMount(){
    this.setup();
  }

  public render() {
    return (
      <>
        <Card title="Select a Day and Event" loading={this.state.loading} help="">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label>Select a Day:</label>
                <DatePicker date={this.state.selectedDay} onDateSaved={this.updateSelectedDate} />
              </div>
            </div>
          </div>
        </Card>

        <Card title="Events" loading={this.state.loading} help="">
          {this.state.events.length === 0 && (
            <div className="row">
              <div className="col-lg-12">
                <strong>No Events are Available On That Day</strong>
              </div>
            </div>
          )}
          {this.state.events.map((e: any) => {
            return (
              // tslint:disable-next-line:jsx-no-lambda
              <div className={"row row-clickable " + e.className} key={e.id} onClick={() => this.selectEvent(e)}>
                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-6">
                  {moment(e.startTime).format("hh:mm")} to {moment(e.endTime).format("hh:mm")} 
                </div>
                <div className="col-lg-8 col-md-8 col-sm-4 col-xs-6">
                  <strong>{e.name}</strong>
                </div>
              </div>
            );
          })}
        </Card>
      </>
    );
  }

  private setup(){
    this.setState({ loading: true }, async () => {
      const now = moment();
      try{
        const start = this.state.selectedDay.local().startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
        const end = this.state.selectedDay.local().endOf("day").utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
        const result = await ScheduleAPI.getEventsBetweenDays(this.props.schoolState.school.id, start, end);
        const events: any[] = [];
        for(const e of result.body.data){
          if(e.privacy !== "public"){
            continue;
          }
          e.className = "";
          const startOfEvent = moment(e.startTime);
          const endOfEvent = moment(e.endTime);
          if(endOfEvent.isBefore(now)){
            e.className = "event-over";
          } else if(startOfEvent.isBefore(now) && endOfEvent.isAfter(now)){
            e.className = "event-now";
          }
          events.push(e);
        }

        this.setState({loading: false, events});
      }catch(err){
        this.setState({loading: false});
      }
    });
  }

  private updateSelectedDate(newDate: moment.Moment){
    this.setState({ selectedDay: newDate}, () => {this.setup();});
  }

  private selectEvent(event: any){
    this.props.history.push(`/attendance/events/${event.id}`);
  }

}


const mapStateToProps = function map(s: any) {
  return {
    appState: s.appState,
    schoolState: s.schoolState,
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    appActions: bindActionCreators(AppActions, dispatch),
    schoolActions: bindActionCreators(SchoolActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AttendanceMode) as React.ComponentType<any>);